import moment from "moment";
import { mapGetters } from "vuex";
import bouncer from "@/helpers/bouncer";
import {
  actions,
  apiRoutes,
  breadcrumbRoutes,
  coreRoutes,
  dateFormats,
  dispatchType,
  employeeAccess,
  mapGettersType,
  notificationMessages,
  typeNotification,
} from "@/helpers/consts";
import catalogService from "@/modules/core/service/catalog.service";
import employeeService from "@/modules/core/service/employee.service";

export default {
  mixins: [bouncer],
  computed: {
    ...mapGetters(mapGettersType.BREAD, [mapGettersType.BREADCRUMB]),
  },
  data() {
    return {
      employeeListRoute: coreRoutes.EMPLOYEE_LIST,
      loading: false,
      employeesMaster: [],
      employeeInformation: {
        city: null,
        city_id: null,
        created_at: null,
        deleted_at: null,
        employee_id: null,
        id: null,
        phone_number: null,
        street: null,
        updated_at: null,
        zip_code: null,
      },
      employee: {
        link_cv: null,
        name: null,
        rol: null,
        position: null,
        subsidiary: null,
        boss: null,
        dni: null,
        health_insurance: null,
        pension: null,
        payment_type: null,
        accounting_code: null,
        account_code: null,
        inactive: null,
        date_admission: null,
        promotion_date: null,
        organization: null,
        retirement_date: null,
        transfer_date: null,
        box_date: null,
        health_date: null,
        pension_date: null,
        subsidiary_transferred: null,
        company: null,
      },
      submitted: false,
      positions: [],
      employees: [],
      subsidiaries: [],
      companies: [],
      organizations: [],
      pensions: [],
      payments: [],
      countries: [],
      cities: [],
      roles: [],
    };
  },
  mounted() {
    if (
        this.canAccess(employeeAccess.EMPLOYEE_CREATE) ||
        this.canAccess(employeeAccess.EMPLOYEE_UPDATE)
    ) {
      this.$store.dispatch(
          dispatchType.BREAD_SET_ITEMS,
          breadcrumbRoutes.EMPLOYEE_CRUD
      );
      if (this.$route.params.id) {
        this.loading = true;
        employeeService
            .get(this.$route.params.id)
            .then((response) => {
              const params = { ...response.data };
              this.employeeInformation = response.data.information;
              params.date_admission = new Date(response.data.date_admission);
              params.promotion_date =
                  new Date(response.data.promotion_date) ?? null;
              params.transfer_date =
                  new Date(response.data.transfer_date) ?? null;
              params.health_date = new Date(response.data.health_date) ?? null;
              params.pension_date = new Date(response.data.pension_date) ?? null;
              params.box_date = new Date(response.data.box_date) ?? null;
              params.retirement_date =
                  new Date(response.data.retirement_date) ?? null;
              params.email = params.user.email ?? null;
              this.employee = params;
            })
            .finally(() => (this.loading = false));
      }

      employeeService.getSelect({}).then((response) => {
        this.employees = response.data;
        this.employeesMaster = response.data;
      });
    }
  },
  methods: {
    notification(severity, summary, detail) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        life: this.$utils.toastLifeTime(),
      });
    },
    save() {
      this.submitted = true;
      if (
          !this.employee.name ||
          !this.employee.dni ||
          !this.employee.date_admission ||
          !this.employee.email ||
          !this.employee.payment_type ||
          !this.employee.position ||
          !this.employee.company ||
          !this.employee.subsidiary ||
          !this.employee.organization ||
          !this.employee.country
      ) {
        this.notification(
            typeNotification.WARN,
            notificationMessages.required_fields,
            notificationMessages.check_information
        );
        return;
      }
      const params = this.formatData();
      const prop = params.id ? actions.UPDATE : actions.SAVE;
      this.loading = true;
      employeeService[prop](params, params.id)
          .then(async (response) => {
            const employee = response.data;
            await this.$router.push(coreRoutes.EMPLOYEE_LIST);
            this.notification(
                typeNotification.SUCCESS,
                notificationMessages.record_saved,
                employee.message
            );
          })
          .catch((error) => {
            const message = error.response.data;
            let errorDetail = "";
            Object.keys(message).forEach((key) => {
              errorDetail +=
                  errorDetail + message[key] && [...message[key]].shift()
                      ? [...message[key]].shift() + "\n "
                      : "";
            });
            this.notification(
                typeNotification.ERROR,
                notificationMessages.error,
                errorDetail
            );
          })
          .finally(() => (this.loading = false));
    },
    formatData() {
      const params = { ...this.employee };
      params.boss_id = this.employee.boss ? this.employee.boss.id : undefined;
      params.position_id = this.employee.position.id;
      params.company_id = this.employee.company.id;
      params.subsidiary_id = this.employee.subsidiary.id;
      params.organization_id = this.employee.organization.id;
      params.country_id = this.employee.country.id;
      params.payment_type_id = this.employee.payment_type.id;
      params.health_insurance_id = this.employee.health_insurance
          ? this.employee.health_insurance.id
          : undefined;
      params.pension_id = this.employee.pension
          ? this.employee.pension.id
          : undefined;
      params.subsidiary_transferred_id = this.employee.subsidiary_transferred
          ? this.employee.subsidiary_transferred.id
          : undefined;
      params.date_admission = moment(this.employee.date_admission).format(
          dateFormats.YYYYMMDD
      );
      params.promotion_date = params.promotion_date
          ? moment(this.employee.promotion_date).format(dateFormats.YYYYMMDD)
          : null;
      params.transfer_date = params.transfer_date
          ? moment(this.employee.transfer_date).format(dateFormats.YYYYMMDD)
          : null;
      params.health_date = params.health_date
          ? moment(this.employee.health_date).format(dateFormats.YYYYMMDD)
          : null;
      params.pension_date = params.pension_date
          ? moment(this.employee.pension_date).format(dateFormats.YYYYMMDD)
          : null;
      params.box_date = params.box_date
          ? moment(this.employee.box_date).format(dateFormats.YYYYMMDD)
          : null;
      params.retirement_date = params.retirement_date
          ? moment(this.employee.retirement_date).format(dateFormats.YYYYMMDD)
          : null;
      params.inactive = params.inactive ? params.inactive : false;
      params.city_id = this.employeeInformation.city.id;
      params.street = this.employeeInformation.street;
      params.zip_code = this.employeeInformation.zip_code;
      params.phone_number = this.employeeInformation.phone_number;
      return params;
    },
    getEmployeeFilter(event) {
      this.employees = this.employeesMaster.filter((employee) => {
        const nameKey = "name";
        return employee[nameKey]
            .toLowerCase()
            .includes(event.query.toLowerCase());
      });
      return this.employees;
    },
    getCatalog(event, route, field, country) {
      const originalRoute = route;
      route = route === apiRoutes.payments ? apiRoutes.payments_types : route;
      route =
          route === apiRoutes.employees
              ? apiRoutes.employees_select + event.query
              : route;
      if (country) {
        if (!this.employee.country) {
          const summary = `País ${notificationMessages.not_selected}`;
          const detail = `${notificationMessages.please_select} un país`;
          this.notification(typeNotification.WARN, summary, detail);
          return;
        }
        route = `${apiRoutes.countries}/${this.employee.country.id}/${route}`;
      }
      catalogService
          .getCatalog(event, route, field)
          .then((response) => {
            this[originalRoute] = response;
          })
          .catch(() => {
            this[originalRoute] = [];
          });
    },
  },
};
