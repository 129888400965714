import httpService from '../../../helpers/http.service'

export default {
  get (id, params) {
    return httpService.get(`employees${id ? '/' + id : ''}`, {params})
  },
  // TODO improve to correct pagination
  getAll(params) {
    return httpService.get(`employees/all`, {params})
  },
  getTb(params) {
    return httpService.get(`employees/tb`, {params})
  },
  getSelect(params) {
    return httpService.get(`employees/select`, {params})
  },
  delete (id) {
    return httpService.delete(`employees/${id}`)
  },
  save (payload) {
    return httpService.post('employees', payload)
  },
  changePassword (id, params) {
    return httpService.post(`employees/${id}/password`, params)
  },
  update (payload,id) {
    return httpService.put(`employees/${id}`, payload)
  },
}
